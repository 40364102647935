import { QueryClient } from 'react-query';
import { notCompletedAuthModalStore } from '../stores/notCompletedAuthModalStore';
import { ErrorResponse, isAuthError } from './errorutils';
import { GET as AUTH_GET_EN } from './en/authClient';
import { GET as ITO_GET_EN } from './en/itoClient';
import { GET as OTC_GET_EN } from './en/otcClient';
import { GET as PAY_GET_EN } from './en/payClient';
import { GET as PROJECT_GET_EN } from './en/projectClient';
import { GET as SUPPORT_GET_EN } from './en/supportClient';
import { GET as WALLET_GET_EN } from './en/walletClient';
import { GET as NOTIF_GET_EN } from './en/notifClient';
import { GET as AUTH_GET_FA } from './fa/authClient';
import { GET as ITO_GET_FA } from './fa/itoClient';
import { GET as OTC_GET_FA } from './fa/otcClient';
import { GET as PAY_GET_FA } from './fa/payClient';
import { GET as PROJECT_GET_FA } from './fa/projectClient';
import { GET as SUPPORT_GET_FA } from './fa/supportClient';
import { GET as WALLET_GET_FA } from './fa/walletClient';
import { GET as NOTIF_GET_FA } from './fa/notifClient';

import { getAppLocale } from 'src/locales/utils';

const getServiceFetcher = (url: string) => {
  if (getAppLocale() == 'en') {
    if (url.startsWith('/api/ito')) return ITO_GET_EN;
    if (url.startsWith('/api/project')) return PROJECT_GET_EN;
    if (url.startsWith('/api/wallet')) return WALLET_GET_EN;
    if (url.startsWith('/api/otc')) return OTC_GET_EN;
    if (url.startsWith('/api/v1/auth')) return AUTH_GET_EN;
    if (url.startsWith('/api/newpay')) return PAY_GET_EN;
    if (url.startsWith('/api/support')) return SUPPORT_GET_EN;
    if (url.startsWith('/api/notif')) return NOTIF_GET_EN;
    throw new Error('undefined api');
  } else if (getAppLocale() == 'fa') {
    if (url.startsWith('/api/ito')) return ITO_GET_FA;
    if (url.startsWith('/api/project')) return PROJECT_GET_FA;
    if (url.startsWith('/api/wallet')) return WALLET_GET_FA;
    if (url.startsWith('/api/otc')) return OTC_GET_FA;
    if (url.startsWith('/api/v1/auth')) return AUTH_GET_FA;
    if (url.startsWith('/api/pay')) return PAY_GET_FA;
    if (url.startsWith('/api/support')) return SUPPORT_GET_FA;
    if (url.startsWith('/api/notif')) return NOTIF_GET_FA;
    throw new Error('undefined api');
  } else throw new Error('undefined api');
};

export const queryClientConfig = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      queryFn: async ({ queryKey, pageParam }) => {
        const page = pageParam?.split('page=')?.[1].at(0);
        if (Array.isArray(queryKey)) {
          if (queryKey[1]?.params?.query && pageParam) queryKey[1].params.query.page = page;
          const GET = getServiceFetcher(queryKey[0] as string);
          if (GET) {
            const {
              data,
              error: undefinedError,
              response,
            } = await GET(queryKey[0] as any, queryKey[1] as never);

            const error = undefinedError as unknown;

            if (error) {
              //@ts-ignore
              if (isAuthError(response) && !!error?.data?.auth) {
                const modalState = notCompletedAuthModalStore.getState();
                modalState.onOpen();
                //@ts-ignore
                modalState.onSetModalProps({ description: error?.data?.auth });
              }
              throw { ...error, response } as ErrorResponse;
            }
            return data;
          }
        }
      },
    },
  },
});
